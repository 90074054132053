<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách phê duyệt quyết toán chi phí</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label v-if="staffStatus == ''" for="staffStatus">[Tất cả phê duyệt]</label>
                                <label v-else for="staffStatus">Phê duyệt</label>
                                <md-select v-model="staffStatus" name="staffStatus" id="staffStatus" multiple class="search">
                                    <md-checkbox @change="selectAllStaffStatus()" v-model="selectedAllStaffStatus" style="padding-left: 16px;">Chọn tất cả</md-checkbox>
                                    <span style="border:1px solid #ccc;"></span>
                                    <md-option value="1">Chờ duyệt</md-option>
                                    <md-option value="2">Đã duyệt</md-option>
                                    <md-option value="4">Không duyệt</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label v-if="status == ''" for="status">[Tất cả trạng thái duyệt]</label>
                                <label v-else for="status">Trạng thái duyệt</label>
                                <md-select v-model="status" name="status" id="status" multiple class="search">
                                    <md-checkbox @change="selectAllStatus()" v-model="selectedAllStatus" style="padding-left: 16px;">Chọn tất cả</md-checkbox>
                                    <span style="border:1px solid #ccc;"></span>
                                    <md-option value="1">Chờ duyệt</md-option>
                                    <md-option value="2">Đã duyệt</md-option>
                                    <md-option value="3">Không duyệt</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label>Tìm theo số quyết toán</label>
                                <md-input v-model="search.code"></md-input>
                            </md-field>
                        </div>
                    </div>
                </div>
                <div class="table-content">
                    <table class="data-table">
                        <thead>
                            <tr>
                                <th style="width:100px;">#</th>
                                <th style="width:15%;">Phê duyệt</th>
                                <th style="width:20%;">Số quyết toán</th>
                                <th style="width:20%;">Người quyết toán</th>
                                <th style="width:15%;">Ngày tạo</th>
                                <th style="width:15%;">Trạng thái</th>
                                <th style="width:10%;">Hành động</th>
                            </tr>
                        </thead>
                        <tbody v-if="loadding == true" style="height: 150px;">
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                            <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && pager.totalItems > 0">
                            <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                <td class="center">{{index + 1}}</td>
                                <td class="center">
                                    <md-chip v-if="item.staffStatus == 1" class="status status-inprogress">Chờ duyệt</md-chip>
                                    <md-chip v-else-if="item.staffStatus == 2" class="status status-done">Đã duyệt</md-chip>
                                    <md-chip v-else-if="item.staffStatus == 4" class="status status-reject">Không duyệt</md-chip>
                                    <md-chip v-else-if="item.staffStatus == 6" class="status status-comment">Ý kiến</md-chip>
                                </td>
                                <td class="center"><router-link :to="'/invoice/approve/' + item.id">{{item.invoiceCode}}</router-link></td>
                                <td class="center">{{item.staff.fullName}}</td>
                                <td class="center">{{item.invoiceDate}}</td>
                                <td class="center">
                                    <md-chip v-if="item.status == 1" class="status status-inprogress">Chờ duyệt ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                    <md-chip v-else-if="item.status == 2" class="status status-done">Đã duyệt ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                    <md-chip v-else-if="item.status == 3" class="status status-reject">Không duyệt ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                    <md-chip v-else-if="item.status == 4" class="status-2 status-edit">Yêu cầu chỉnh sửa ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                    <md-chip v-else-if="item.status == 6" class="status status-comment">Ý kiến ({{item.levelApprove}}/{{item.totalApprove}})</md-chip>
                                </td>
                                <td class="action">
                                    <router-link :to="'/invoice/approve/' + item.id">
                                        <md-button class="md-fab md-mini md-view">
                                            <i class="uil uil-eye"></i>
                                            <md-tooltip>Xem</md-tooltip>
                                        </md-button>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="table-footer" v-if="pager.totalPages > 1">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">{{paging.firstPage}}</a>
                                    <a @click="onPage(search.pageIndex - 1)">{{paging.prePage}}</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>{{paging.page}}</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">{{paging.of}} <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">{{paging.rowOfPage}}:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">{{paging.view}}:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> {{paging.rows}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">{{paging.nextPage}}</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">{{paging.lastPage}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import invoiceService from '../../../api/invoiceService';
    import messageBox from '../../../utils/messageBox';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';

    export default {
        metaInfo: {
            title: 'Danh sách quyết toán chi phí'
        },
        data() {
            return {
               loadding: false,
               search: { pageIndex: 1, pageSize: common.pageSize, status: '', staffStatus: '', code: '' },
               pager: { totalPages: 1 },
               data: [],
               rowPerPageOptions: [],
               id: 0,
               status: [],
               selectedAllStatus: false,
               staffStatus: [],
               selectedAllStaffStatus: false
            }
        },
        created(){
            this.rowPerPageOptions = common.rowPerPageOptions;
            if(this.$route.query.page != undefined){
               this.search.pageIndex = parseInt(this.$route.query.page);
            }
            if(this.$route.query.pageSize != undefined){
               this.search.pageSize = parseInt(this.$route.query.pageSize);
            }
            if(this.$route.query.status != undefined){
               this.search.status = this.$route.query.status;
               this.status = this.$route.query.status.split(',')
            }
            else{
                this.status = [];
            }
            if(this.$route.query.staffStatus != undefined){
               this.search.staffStatus = this.$route.query.staffStatus;
               this.staffStatus = this.$route.query.staffStatus.split(',')
            }
            else{
                this.staffStatus = [];
            }
            if(this.$route.query.code != undefined){
               this.search.code = this.$route.query.code;
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            selectAllStaffStatus(){
                if(this.selectedAllStaffStatus){
                    this.staffStatus = ['1','2','4'];
                }
                else{
                    this.staffStatus = [];
                }
            },

            selectAllStatus(){
                if(this.selectedAllStatus){
                    this.status = ['1','2','3'];
                }
                else{
                    this.status = [];
                }
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            refresh(){
                this.search.status = '';
                this.search.staffStatus = '';
                this.status = [];
                this.staffStatus = [];
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize + "&status=" + "&staffStatus=" + "&code=";
                this.$router.push('/invoice/approve-list?' + url).catch(()=>{});
                this.getData();
            },

            filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize + "&status=" + this.search.status + "&staffStatus=" + this.search.staffStatus + "&code=" + this.search.code;
                this.$router.push('/invoice/approve-list?' + url).catch(()=>{});
                this.getData();
            },

            getData(){
                this.loadding = true;
                invoiceService.getForApproves(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            }    
        },
        watch: {
            staffStatus: function () {
                this.search.staffStatus = String(this.staffStatus);
                if(this.staffStatus.length < 3){
                    this.selectedAllStaffStatus = false;
                }
                else{
                    this.selectedAllStaffStatus = true;
                }
                this.filter();
            },
            status: function () {
                this.search.status = String(this.status);
                if(this.status.length < 3){
                    this.selectedAllStatus = false;
                }
                else{
                    this.selectedAllStatus = true;
                }
                this.filter();
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
            'search.code': function () {
                this.search.pageIndex = 1;
                this.filter();
            },
        }
    }

</script>
